/***************************

    Component: Hero

***************************/

.hero {
  @include flexbox();
  background-size: cover;
  min-height: 450px;
  position: relative;
  overflow: hidden;
  background: #222222;
  @include media-breakpoint-down-md {
    min-height: 300px;
  }
  &:after {
    content: '';
    height: 130px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-size: contain;
    z-index: 1;
  }
  &-content {
    @include flexbox();
    @include justify-content(center);
    color: #ffffff;
    z-index: 1;
    width: 100%;
    margin: 0 0 20px 0;
    position: relative;
    @include media-breakpoint-down-md {
      height: 250px;
    }
  }
  &-heading {
    @include media-breakpoint-down-md {
      font: {
        size: 25px;
      }
    }
    font: {
      size: 40px;
      weight: 800;
    }
    line-height: 1.2;
    margin: 0;
    color: #ffffff;
  }
  &-subheading {
    @include media-breakpoint-down-md {
      font: {
        size: 26px;
      }
    }
    font: {
      size: 38px;
      weight: 900;
    }
    line-height: 1.2;
    background-color: #ffffff;
    color: #e11411;
    padding: 5px 20px;
    display: table;
    margin: 5px 0 10px;
    transform: rotate(-2deg);
  }
  &-buttons {
    padding: 10px 0 0;
    display: flex;
  }
  &-main {
    min-height: 450px;
    height: 60vh;
  }
}