/***************************

    Component: CTA

***************************/

.cta {
  @include flexbox();
  @include justify-content(center);
  background: #333333;
  width: 100%;
  padding: 100px 0;
  color: #ffffff;
  position: relative;
  &-content {
    position: relative;
    z-index: 1;
  }
  &-heading {
    margin: 0 0 15px 0;
    font: {
      size: 30px;
      weight: 700;
    }
  }
  &-badge {
    display: inline-block;
    padding: 5px 10px 0 0;
    img {
      height: 50px;
    }
  }
}
