.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba($primary-colour, 0.8);
  z-index: 9;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -0.5px;
    width: 200px;
    height: 100px;
    p {
      color: #ffffff;
    }
    .spinner {
      div {
        border: 6px solid #ffffff!important;
        border-color: #ffffff transparent transparent transparent!important;
      }
    }
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 6px;
    border: 6px solid #5d5d5d;
    @include border-radius(50%);
    @include animation(spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite);
    border-color: #5d5d5d transparent transparent transparent;
    &:nth-child(1) {
      @include animation-delay(-0.45s);
    }
    &:nth-child(2) {
      @include animation-delay(-0.3s);
    }
    &:nth-child(3) {
      @include animation-delay(-0.15s);
    }
  }
}

@include keyframes(spinner) {
  0% {
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}
