/***************************

    Layout: Base

***************************/

#root {
  display: flex;
  height: fit-content;
  flex-direction: column;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #000000;
  color: #BBBBBB;
  font: {
     family: $font-family;
     size: $font-size-base;
     weight: $font-weight-base;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height-base;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  color: #ffffff;
}

main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

label {
  text-transform: uppercase;
  color: #ffffff;
  font: {
    size: 12px;
    weight: 700;
  }
  padding: 0 0 5px 0;
  display: block;
  width: 100%;
}

input {
  background: rgba($primary-colour, 0.3);
  height: 50px;
  width: 100%;
  padding: 5px 15px;
  &::placeholder {
    color: rgba(#ffffff, 0.5);
  }
}

select {
  border: none;
  @include appearance(none);
  min-height: 45px;
  &::-ms-expand {
    display: none;
  }
}

.select {
  &-wrapper {
    position: relative;
    width: 250px;
    margin: 0 auto;
    &:before {
      content: '';
      position: absolute;
      border: solid $primary-colour;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      z-index: 1;
      top: 16px;
      right: 15px;
      @include transform(rotate(45deg));
    }
  }
}

.top-margin {
  margin: 30px 0 0;
}

.page-content {
  flex: 1 0 auto;
}

.status-msg {
  padding: 0 0 10px 0;
  font: {
    size: 16px;
    weight: 700;
  }
}

.status {
  &-success {
    @extend .status-msg;
    color: #4affbe;
  }
  &-error {
    @extend .status-msg;
    color: #e76666;
  }
}

.modal {
  &-dialog {
    z-index: 9;
    background: rgba(0,0,0,.7);
    height: 100vh;
  }
}