

footer {
  background: rgba(250,250,250,0.07);
  padding: 50px 0;
}

.footer {
  &-copyright {
    color: #A8A8A8;
    font-size: 13px;
    text-align: center;
    a {
      color: #A8A8A8;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
