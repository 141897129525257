.profile-heading {
  font: {
    size: 20px;
    weight: bold;
  }
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 0 30px 0;
}

.invite {
  background: rgba(48, 48, 48, 0.55);
  margin: 20px 0;
  border-radius: 5px;
  &-details {
    padding: 20px 25px 0 25px;
  }
  &-list {
    border-top: 1px solid #333333;
    margin: 15px 0 0;
  }
  &-code {
    &:first-child {
      margin: 20px 0 0;
    }
    color: #ffffff;
    padding: 0 25px 0;
    display: block;
    width: 100%;
  }
  &-link {
    padding: 0;
    background: #525252;
    border-radius: 3px;
    color: #ffffff;
    margin: 0 0 15px 0;
    input {
      color: #bbbbbb;
      background: transparent;
      border: 0;
      padding: 0;
    }
  }
  &-remaining {
    padding: 5px 0 0 0;
  }
  &-fineprint {
    font: {
      size: 12px;
    }
    padding: 0 25px 0;
    color: #999999;
    margin: 15px 0 20px 0;
  }
}

.manage {
  border-bottom: 1px solid #333333;
  min-height: 100px;
  padding: 0 0 50px 0;
  display: flow-root;
  &-user {
    margin: 5px 0;
    border-bottom: 1px solid rgba(250,250,250,0.1);
    padding: 10px 0 15px 0;
    vertical-align: middle;
    font: {
      size: 16px;
      weight: bold;
    }
    color: #ffffff;
  }
  &-icon { 
    display: inline-block;
    border-radius: 100%;
    margin: 0 15px 0 0;
    vertical-align: middle;
    img {
      width: 50px;
      height: 50px;
    }
  }
}

.redeem {
  &-header {
    background: rgba(250, 250, 250 ,0.1);
    width: 100%;
    color: #ffffff;
    padding: 10px 0;
  }
  &-sidebar {
    background: rgba(#303030, 0.55);
    padding: 30px;
    height: 100%;
    @include media-breakpoint-down-md {
      margin: 0 0 20px 0;
    }
  }
  &-section {
    margin: 10px 0;
    span {
      color: #ffffff;
      font: {
        size: 20px;
        weight: 600;
      }
    }
  }
  &-title {
    color: #9EBBE7;
    text-transform: uppercase;
    font: {
      size: 14px;
      weight: 700;
    }
  }
  &-input {
    border: 2px dashed #ffffff;
    letter-spacing: 10px;
    text-align: center;
    @include border-radius(3px);
    color: #ffffff;
    width: 100%;
    font: {
      weight: 700;
    }
  }
}

.voucher {
  margin: 50px 0;
}
