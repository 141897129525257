/***************************

    Component: Sections

***************************/

section {
  padding: 60px 0;
  display: flex;
}

.section {
  &-subscriptions {
    background: #363636 center;
    background-size: cover;
  }
  &-redeem {
    display: flex;
  }
  &-intro {
    color: #ffffff;
    margin: 0;
    font: {
      size: 25px;
      weight: 600;
    }
  }
  &-heading {
    color: #000000;
    line-height: 1.2;
    margin: 5px 0 30px 0;
    font: {
      size: 40px;
      weight: 700;
    }
    @include media-breakpoint-down-md {
      font: {
        size: 32px;
      }
    }
  }
  &-hero {
    background: #363636 center;
    background-size: cover;
    padding: 160px 0 100px;
  }
  &-paymentmethods {
    background: #ffffff repeat;
    .section-heading, p {
      color: $primary-colour;
    }
  }
  &-premium {
    h3 {
      margin-bottom: 0;
    }
  }
}

.premium-icon {
  width: 130px;
}

.premium-badge {
  @include media-breakpoint-down-md {
    margin: 0 0 20px 0;
  }
}

.label {
  &-payment {
    text-transform: none; 
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 0 0 0 0; 
    display: block;
    width: 50%;
    text-align: left;
  }
  &-options {
    white-space: nowrap;
    text-transform: initial;
    background: rgba(250,250,250, 0.05);
    padding: 8px 0;
    margin: 0 0 3px;
    cursor: pointer;
    @include border-radius(3px);
  }
}

.input-radio {
  height: initial;
  width: auto;
  margin: 0 10px 0 0;
}

.package-name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  margin: 10px 0;
}

.package-button {
  margin: 10px 0 0;
  color: $primary-colour;
  display: inline-block;
}

.other-options {
  margin: 70px 0 0;
  border-top: 1px solid #e2e2e2;
  padding: 50px 0 0;
}

.collapse {
  display: none;
  @include border-radius(3px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px 20px;
  border: 2px solid #e2e2e2;
  margin: -7px 0 5px;
  &.show {
    display: block;
  }
}

.premium {
  &-payment {
    color: #000000;
    background: #ffffff;
  }
  &-package {
    padding: 20px;
    border: 1px solid #999999;
    border-radius: 3px;
    border: 1px solid #ced4da;
    margin: 30px 0 0;
  }
}

.form {
  &-group {
    margin: 0 0 20px 0;
  }
  &-input {
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    
  }
}
