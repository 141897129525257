
.u-text-center {
  text-align: center;
}

.u-text-white {
  color: #ffffff;
}

.u-text-fineprint {
  font: {
    size: 13px;
  }
}

.u-margin-top {
  margin: 40px 0 0;
}

.u-align-right { 
  text-align: right;
}

.u-no-margin-bottom {
  margin-bottom: 0;
}

.u-no-margin-top {
  margin-top: 0;
}