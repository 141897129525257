/***************************

    Component: Buttons

***************************/

.buttons {
  height: 50px;
  @include media-breakpoint-down-md {
    padding: 10px 10px !important;
  }
  @include transition(background .1s ease-out, background .1s ease-in);
  cursor: pointer;
  padding: 10px 15px;
  @include border-radius(3px);
  text-decoration: none;
  font: {
    weight: 600;
    size: 16px;
  }
}


.btn {
  &-primary {
    @extend .buttons;
    background: $secondary-colour !important;
    border: 2px solid transparent;
    @include media-breakpoint-down-md {
      margin: 0 7px 0 0;
    }
    margin: 0 15px 0 0;
    &:hover,
    &:active {
      background: lighten($secondary-colour, 10%)!important;
      border: 2px solid lighten($secondary-colour, 10%)!important;
    }
  }
  &-secondary {
    @extend .buttons;
    background: transparent;
    border: 2px solid #ffffff;
    &:hover,
    &:active {
      background: #ffffff!important;
      color: $primary-colour!important;
      border: 2px solid #ffffff!important;
    }
  }
  &-validate {
    height: 50px;
    width: 100%;
    display: block;
    text-align: center;
    @include media-breakpoint-down-md {
      margin: 10px 0;
    }
  }
  &-logout {
    margin: 40px 0 0;
  }
  &-buy {
    @extend .buttons;
    background: $secondary-colour !important;
    border: 2px solid transparent;
    color: #ffffff;
    margin: 10px 0 0;
    display: inline-block;
    &:hover,
    &:active {
      background: darken($secondary-colour, 20%)!important;
      border: 2px solid darken($secondary-colour, 20%)!important;
    }
  }
  &-edit {
    @extend .buttons;
    background: #333333 !important;
    color: #ffffff;
    border: 2px solid transparent;
    margin: 0px;
    padding: 10px!important;
    height: 40px;
    float: right;
    margin-top: -5px;
  }
  &-profile {
    @extend .buttons;
    background: #333333 !important;
    color: #ffffff;
    border: 2px solid transparent;
    margin: 5px 0 0;
    padding: 10px!important;
    height: 40px;
  }
  &-remove {
    @extend .buttons;
    background: transparent;
    border: 2px solid #555555;
    border-radius: 50px;
    color: #555555;
    padding: 0 20px;
    &:hover,
    &:active {
      background: #555555!important;
      color: $primary-colour!important;
      border: 2px solid #555555!important;
    }
  }
  &-copy {
    @extend .buttons;
    border-radius: 100%;
    background: #000000;
    color: #ffffff;
    width: 40px;
    height: 40px;
    padding: 0;
    img {
      width: 15px;
      height: 15px;
    }
  }
}