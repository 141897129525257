

@mixin media-breakpoint-down-sm {
  @media (max-width: $breakpoint-down-sm) {
     @content;
  }
}

@mixin media-breakpoint-down-md {
  @media (max-width: $breakpoint-down-md) {
     @content;
  }
}

@mixin media-breakpoint-up-sm {
  @media (min-width: $breakpoint-up-sm) {
     @content;
  }
}

@mixin media-breakpoint-up-md {
  @media (min-width: $breakpoint-up-md) {
     @content;
  }
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($val...) {
  -webkit-transition: $val;
  -moz-transition: $val;
  -ms-transition: $val;
  transition: $val;
}

@mixin animation($animation-val...) {
  -webkit-animation: $animation-val;
  -moz-animation: $animation-val;
  -ms-animation: $animation-val;
}

@mixin animation-delay($val...) {
  -webkit-animation-delay: $val;
  -moz-animation-delay: $val;
  -ms-animation-delay: $val;
}

@mixin box-shadow($shadow-val) {
  -webkit-box-shadow: $shadow-val;
  -moz-box-shadow: $shadow-val;
  -ms-box-shadow: $shadow-val;
  box-shadow: $shadow-val;
}

@mixin box-sizing($box-model: border-box) {
  -webkit-box-sizing: $box-model;
  -moz-box-sizing: $box-model;
  box-sizing: $box-model;
}

@mixin border-radius($radius: 3px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
     -moz-flex-direction: $direction;
      -ms-flex-direction: $direction;
          flex-direction: $direction;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
     -moz-box-flex: $values;
      -webkit-flex: $values;
  	  -ms-flex: $values;
  	      flex: $values;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
     -moz-align-content: $align;
      -ms-align-content: $align;
          align-content: $align;
}

@mixin stroke($color: #000, $size: 1px) {
  text-shadow:
    -#{$size} -#{$size} 0 $color,
     0        -#{$size} 0 $color,
     #{$size} -#{$size} 0 $color,
     #{$size}  0        0 $color,
     #{$size}  #{$size} 0 $color,
     0         #{$size} 0 $color,
    -#{$size}  #{$size} 0 $color,
    -#{$size}  0        0 $color;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
     -moz-appearance: $value;
      -ms-appearance: $value;
}