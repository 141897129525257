header {
  width: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
}

.header {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  top: 0;
  left: 0;
  z-index: 2;
  @include media-breakpoint-down-md {
    padding: 7px 20px;
  }
}

.logo {
  margin: 0 20px 0 0;
  img {
    width: 200px;
    @include media-breakpoint-down-md {
      width: 140px;
    }
  }
}

.nav {
  &-group {
    display: inline-flex;
    align-items: center;
  }
  &-desktop {
    @include media-breakpoint-down-md {
      display: none;
    }
    display: inline-block;
    margin: auto;
    ul {
      list-style: none;
      li {
        display: inline-block;
        margin: 0 25px 0 0;
        a {
          @include transition(color .2s ease-out, background 1s ease-in);
          color: #000000;
          text-decoration: none;
          font: {
            size: 17px;
            weight: 800;
          }
          &:hover {
            color: $secondary-colour;
          }
        }
      }
    }
  }
  &-mobile {
    @include media-breakpoint-up-md {
      display: none;
    }
    ul {
      list-style: none;
      outline: none;
      width: 100%;
      li {
        margin: 0 0 15px 0;
        a {
          color: #ffffff;
          text-decoration: none;
          display: block;
          font: {
            size: 25px;
            weight: 700;
          }
        }
      }
    }
  }
  &-btn {
    border: 2px solid $secondary-colour;
    color: $secondary-colour;
    text-decoration: none;
    border-radius: 3px;
    padding: 7px 10px;
    cursor: pointer;
    text-align: center;
    line-height: 20px;
    @include transition(background .1s ease-out, background .1s ease-in);
    @include media-breakpoint-down-md {
      margin: 0 10px 0 0;
      padding: 5px 9px;
      font-size: 14px;
      line-height: 17px;
    }
    font: {
      weight: 700;
    }
    @include media-breakpoint-up-md {
      margin: 0 5px;
    }
    &:hover {
      background: $secondary-colour;
      color: #ffffff;
    }
    &-link {
      cursor: pointer;
      margin: 0;
      padding: 0;
      color: #A8A8A8!important;
      color: $secondary-colour;
      text-decoration: none;
      font: {
        size: 14px;
        weight: 400;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-footer {
    position: relative;
    padding: 20px 0 0;
    margin: 50px 0 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 80px;
      border: 0.5px solid #42464D;
    }
  }
}

.bm-burger {
  &-button {
    position: relative;
    width: 30px;
    height: 30px;
    @include media-breakpoint-down-md {
      width: 25px;
      height: 25px;
    }
  }
  &-bars {
    background: #000000;
    height: 3px!important;
  }
}

.bm-cross {
  background: #ffffff;
  &-button {
    height: 40px!important;
    width: 40px!important;
   
    span {
      width: 3px!important;
      height: 35px!important;
    }
  }
}

.bm-menu {
  background: $primary-colour;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  &-wrap {
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%!important;
  }
  .footer-copyright {
    text-align: left;
  }
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item {
  width: 100%;
  outline: none;
  &-list {
    max-width: 300px;
    margin: 0 auto;
    @include flexbox();
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.language {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: right;
  text-align: right;
  flex-direction: row;
  top: 0;
  left: 0;
  z-index: 2;
  height: 26px;
  background: #e1e1e1;
  &-select {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #333333;
    font-weight: 500;
    button {
      color: #333333;
      padding: 0;
      font-size: 15px;
      cursor: pointer;
      &.active {
        color: #333333;
        font-weight: 800;
      }
      &:hover {
        color: #555555;
      }
    }
    li {
      display: inline-block;
      padding: 0 2px;
      &:after {
        content: '·';
        padding: 0 0 0 4px;
      }
      &:last-child {
        padding-right: 0;
        &:after {
          display:none;
        }
      }
    }
  }
}