.phonelogin-container,.mdl-card,.mdl-card__actions,.mdl-card__media,.mdl-card__title,.mdl-spinner__circle,.mdl-textfield {
  box-sizing: border-box
}

.mdl-button,.mdl-button .material-icons {
  vertical-align: middle
}

.mdl-button {
  background: 0 0;
  border: none;
  @include border-radius(2px);
  color: #000;
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow;
  transition: box-shadow .2s cubic-bezier(.4,0,1,1),background-color .2s cubic-bezier(.4,0,.2,1),color .2s cubic-bezier(.4,0,.2,1);
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px
}

.mdl-button--fab,.mdl-button--icon {
  font-size: 24px;
  padding: 0;
  overflow: hidden
}

.mdl-button::-moz-focus-inner {
  border: 0
}

.mdl-button:hover {
  background-color: rgba(158,158,158,.2)
}

.mdl-button:focus:not(:active) {
  background-color: rgba(0,0,0,.12)
}

.mdl-button:active {
  background-color: rgba(158,158,158,.4)
}

.mdl-button.mdl-button--colored {
  color: #3f51b5
}

.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0,0,0,.12)
}

input.mdl-button[type=submit] {
  -webkit-appearance: none
}

.mdl-button--raised {
  background: darken($secondary-colour, 10%);
}

.mdl-button--raised:active {
  background-color: darken($secondary-colour, 15%);
}

.mdl-button--raised:focus:not(:active) {
 
  background-color: darken($secondary-colour, 15%);
}

.mdl-button--raised.mdl-button--colored {
  background-color: darken($secondary-colour, 15%);
  color: #fff
}

.mdl-button--raised.mdl-button--colored:active,.mdl-button--raised.mdl-button--colored:focus:not(:active),.mdl-button--raised.mdl-button--colored:hover {
  background-color: darken($secondary-colour, 10%);
}

.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: #fff
}

.mdl-button--fab {
  @include border-radius(50%);
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  background: rgba(158,158,158,.2);
  box-shadow: 0 1px 1.5px 0 rgba(0,0,0,.12),0 1px 1px 0 rgba(0,0,0,.24);
  position: relative;
  line-height: normal
}

.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px,-12px);
  line-height: 24px;
  width: 24px
}

.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px
}

.mdl-button--fab .mdl-button__ripple-container {
  @include border-radius(50%);
  -webkit-mask-image: -webkit-radial-gradient(circle,#fff,#000)
}

.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
  background-color: rgba(158,158,158,.4)
}

.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0,0,0,.18),0 8px 16px rgba(0,0,0,.36);
  background-color: rgba(158,158,158,.4)
}

.mdl-button--fab.mdl-button--colored {
  background: #ff4081;
  color: #fff
}

.mdl-button--fab.mdl-button--colored:active,.mdl-button--fab.mdl-button--colored:focus:not(:active),.mdl-button--fab.mdl-button--colored:hover {
  background-color: #ff4081
}

.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: #fff
}

.mdl-button--icon {
  @include border-radius(50%);
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  color: inherit;
  line-height: normal
}

.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px,-12px);
  line-height: 24px;
  width: 24px
}

.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px
}

.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0;
  left: 0
}

.mdl-button--icon .mdl-button__ripple-container {
  @include border-radius(50%);
  -webkit-mask-image: -webkit-radial-gradient(circle,#fff,#000)
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden
}

.mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple,.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent
}

.mdl-button--primary.mdl-button--primary {
  color: #757575
}

.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: #fff
}

.mdl-button--primary.mdl-button--primary.mdl-button--fab,.mdl-button--primary.mdl-button--primary.mdl-button--raised {
  color: #fff;
  background-color: #3f51b5
}

.mdl-button--accent.mdl-button--accent {
  color: #ff4081
}

.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: #fff
}

.mdl-button--accent.mdl-button--accent.mdl-button--fab,.mdl-button--accent.mdl-button--accent.mdl-button--raised {
  color: #fff;
  background-color: #ff4081
}

.mdl-button.mdl-button--disabled.mdl-button--disabled,.mdl-button[disabled][disabled] {
  color: rgba(0,0,0,.26);
  cursor: default;
  background-color: transparent
}

.mdl-button--fab.mdl-button--disabled.mdl-button--disabled,.mdl-button--fab[disabled][disabled] {
  background-color: rgba(0,0,0,.12);
  color: rgba(0,0,0,.26)
}

.mdl-button--raised.mdl-button--disabled.mdl-button--disabled,.mdl-button--raised[disabled][disabled] {
  background-color: rgba(0,0,0,.12);
  color: rgba(0,0,0,.26);
  box-shadow: none
}

.mdl-button--colored.mdl-button--disabled.mdl-button--disabled,.mdl-button--colored[disabled][disabled] {
  color: rgba(0,0,0,.26)
}

.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  max-width: 350px;
  min-width: 300px;
  z-index: 1;
  background: #fff;
  @include border-radius(2px);
  position: fixed;
  background: white;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.mdl-card__media {
  background-color: #ff4081;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll
}

.mdl-card__title {
  align-items: center;
  color: #000;
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px
}

.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0,0,0,.1)
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0,.54);
  margin: 0
}

.mdl-card__supporting-text {
  color: rgba(0,0,0,.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px;
  width: 90%
}

.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0,0,0,.1)
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: transparent;
  padding: 8px
}

.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0,0,0,.1)
}

.mdl-card--expand {
  flex-grow: 1
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px
}

.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0,0,0,.14),0 11px 15px -7px rgba(0,0,0,.12),0 24px 38px 3px rgba(0,0,0,.2);
  width: 280px
}

.mdl-dialog__title {
  padding: 24px 24px 0;
  margin: 0;
  font-size: 2.5rem
}

.mdl-dialog__actions {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap
}

.mdl-dialog__actions>* {
  margin-right: 8px;
  height: 36px
}

.mdl-dialog__actions>:first-child {
  margin-right: 0
}

.mdl-dialog__actions--full-width {
  padding: 0 0 8px
}

.mdl-dialog__actions--full-width>* {
  height: 48px;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right
}

.mdl-dialog__content {
  padding: 20px 24px 24px;
  color: rgba(0,0,0,.54)
}

.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%
}

.mdl-progress>.bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width .2s cubic-bezier(.4,0,.2,1)
}

.mdl-progress>.progressbar {
  background-color: #3f51b5;
  z-index: 1;
  left: 0
}

.mdl-progress>.bufferbar {
  background-image: linear-gradient(to right,rgba(255,255,255,.7),rgba(255,255,255,.7)),linear-gradient(to right,#3f51b5,#3f51b5);
  z-index: 0;
  left: 0
}

.mdl-progress>.auxbar {
  right: 0
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate)>.auxbar,.mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate)>.auxbar {
      background-image:linear-gradient(to right,rgba(255,255,255,.7),rgba(255,255,255,.7)),linear-gradient(to right,#3f51b5,#3f51b5);
      mask: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+Cjxzdmcgd2lkdGg9IjEyIiBoZWlnaHQ9IjQiIHZpZXdQb3J0PSIwIDAgMTIgNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxlbGxpcHNlIGN4PSIyIiBjeT0iMiIgcng9IjIiIHJ5PSIyIj4KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPgogIDwvZWxsaXBzZT4KICA8ZWxsaXBzZSBjeD0iMTQiIGN5PSIyIiByeD0iMiIgcnk9IjIiIGNsYXNzPSJsb2FkZXIiPgogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L2VsbGlwc2U+Cjwvc3ZnPgo=)
  }
}

.mdl-progress:not(.mdl-progress--indeterminate)>.auxbar,.mdl-progress:not(.mdl-progress__indeterminate)>.auxbar {
  background-image: linear-gradient(to right,rgba(255,255,255,.9),rgba(255,255,255,.9)),linear-gradient(to right,#3f51b5,#3f51b5)
}

.mdl-progress.mdl-progress--indeterminate>.bar1,.mdl-progress.mdl-progress__indeterminate>.bar1 {
  background-color: #3f51b5;
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear
}

.mdl-progress.mdl-progress--indeterminate>.bar3,.mdl-progress.mdl-progress__indeterminate>.bar3 {
  background-image: none;
  background-color: #3f51b5;
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear
}

@keyframes indeterminate1 {
  0% {
      left: 0;
      width: 0%
  }

  50% {
      left: 25%;
      width: 75%
  }

  75% {
      left: 100%;
      width: 0%
  }
}

@keyframes indeterminate2 {
  0%,50% {
      left: 0;
      width: 0%
  }

  75% {
      left: 0;
      width: 25%
  }

  100% {
      left: 100%;
      width: 0%
  }
}

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12)
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.2),0 1px 8px 0 rgba(0,0,0,.12)
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2)
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12),0 3px 5px -1px rgba(0,0,0,.2)
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12),0 5px 5px -3px rgba(0,0,0,.2)
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.14),0 6px 30px 5px rgba(0,0,0,.12),0 8px 10px -5px rgba(0,0,0,.2)
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0,0,0,.14),0 11px 15px -7px rgba(0,0,0,.12),0 24px 38px 3px rgba(0,0,0,.2)
}

.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px
}

.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading..."
}

.mdl-spinner.is-upgraded.is-active {
  animation: mdl-spinner__container-rotate 1.568s linear infinite
}

@keyframes mdl-spinner__container-rotate {
  to {
      transform: rotate(360deg)
  }
}

.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0
}

.mdl-spinner__layer-1 {
  border-color: #42a5f5
}

.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: #3f51b5
}

.mdl-spinner.is-active .mdl-spinner__layer-1 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(.4,0,.2,1) infinite both,mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(.4,0,.2,1) infinite both
}

.mdl-spinner__layer-2 {
  border-color: #f44336
}

.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: #3f51b5
}

.mdl-spinner.is-active .mdl-spinner__layer-2 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(.4,0,.2,1) infinite both,mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(.4,0,.2,1) infinite both
}

.mdl-spinner__layer-3 {
  border-color: #fdd835
}

.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: #3f51b5
}

.mdl-spinner.is-active .mdl-spinner__layer-3 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(.4,0,.2,1) infinite both,mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(.4,0,.2,1) infinite both
}

.mdl-spinner__layer-4 {
  border-color: #4caf50
}

.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: #3f51b5
}

.mdl-spinner.is-active .mdl-spinner__layer-4 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(.4,0,.2,1) infinite both,mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(.4,0,.2,1) infinite both
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
      transform: rotate(135deg)
  }

  25% {
      transform: rotate(270deg)
  }

  37.5% {
      transform: rotate(405deg)
  }

  50% {
      transform: rotate(540deg)
  }

  62.5% {
      transform: rotate(675deg)
  }

  75% {
      transform: rotate(810deg)
  }

  87.5% {
      transform: rotate(945deg)
  }

  to {
      transform: rotate(1080deg)
  }
}

@keyframes mdl-spinner__layer-1-fade-in-out {
  100%,25%,90%,from {
      opacity: .99
  }

  26%,89% {
      opacity: 0
  }
}

@keyframes mdl-spinner__layer-2-fade-in-out {
  15%,51%,from {
      opacity: 0
  }

  25%,50% {
      opacity: .99
  }
}

@keyframes mdl-spinner__layer-3-fade-in-out {
  40%,76%,from {
      opacity: 0
  }

  50%,75% {
      opacity: .99
  }
}

@keyframes mdl-spinner__layer-4-fade-in-out {
  100%,65%,from {
      opacity: 0
  }

  75%,90% {
      opacity: .99
  }
}

.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit
}

.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%
}

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit
}

.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left
}

.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right
}

.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%
}

.mdl-spinner__circle {
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent!important;
  @include border-radius(50%);
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent!important;
  transform: rotate(129deg)
}

.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  animation: mdl-spinner__left-spin 1333ms cubic-bezier(.4,0,.2,1) infinite both
}

.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent!important;
  transform: rotate(-129deg)
}

.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  animation: mdl-spinner__right-spin 1333ms cubic-bezier(.4,0,.2,1) infinite both
}

@keyframes mdl-spinner__left-spin {
  from,to {
      transform: rotate(130deg)
  }

  50% {
      transform: rotate(-5deg)
  }
}

@keyframes mdl-spinner__right-spin {
  from,to {
      transform: rotate(-130deg)
  }

  50% {
      transform: rotate(5deg)
  }
}

.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0
}

.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px
}

.mdl-textfield--align-right {
  text-align: right
}

.mdl-textfield--full-width {
  width: 100%
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px
}

.mdl-textfield__input,.mdl-textfield__label {
  font-size: 16px;
  width: 100%;
  display: block;
  text-align: left
}

.mdl-textfield--expandable .mdl-button--icon {
  top: 16px
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0,.12);
  font-family: Roboto,Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 4px 0;
  background: 0 0;
  color: inherit
}

.mdl-textfield__input::placeholder {
  color: #aaaaaa;
} 

.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label,.mdl-textfield__label,fieldset[disabled] .mdl-textfield .mdl-textfield__label {
  color: rgba(0,0,0,.26)
}

.mdl-textfield__input[type=number] {
  -moz-appearance: textfield
}

.mdl-textfield__input[type=number]::-webkit-inner-spin-button,.mdl-textfield__input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: 0
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #d50000;
  box-shadow: none
}

.mdl-textfield.is-disabled .mdl-textfield__input,fieldset[disabled] .mdl-textfield .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0,0,0,.12);
  color: rgba(0,0,0,.26)
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block
}

.mdl-textfield__label {
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  top: 24px;
  overflow: hidden;
  white-space: nowrap
}

.mdl-textfield.has-placeholder .mdl-textfield__label,.mdl-textfield.is-dirty .mdl-textfield__label {
  visibility: hidden
}

.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none
}

.mdl-textfield__expandable-holder,.mdl-textfield__label:after {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label,.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #3f51b5;
  font-size: 12px;
  top: 4px;
  visibility: visible
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label,.mdl-textfield__error {
  color: #d50000;
  font-size: 12px
}

.mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label,.mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label,.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px
}

.mdl-textfield__label:after {
  background-color: #3f51b5;
  bottom: 20px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  visibility: hidden;
  width: 10px
}

.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%
}

.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #d50000
}

.mdl-textfield__error {
  position: absolute;
  margin-top: 3px;
  visibility: hidden;
  display: block
}

.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible
}

.mdl-textfield__expandable-holder {
  position: relative;
  margin-left: 32px;
  display: inline-block;
  max-width: .1px
}

.mdl-textfield.is-dirty .mdl-textfield__expandable-holder,.mdl-textfield.is-focused .mdl-textfield__expandable-holder {
  max-width: 600px
}

.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0
}

dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: #fff;
  color: #000;
  display: block
}

dialog:not([open]) {
  display: none
}

dialog+.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,.1)
}

._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0,-50%)
}

.phonelogin-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  overflow: visible;
  text-align: left;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.phonelogin-container.mdl-card {
  overflow: visible
}

.phonelogin-card-header {
  padding: 24px 24px 0
}

.phonelogin-card-content,.phonelogin-card-footer {
  padding: 0 24px
}

.phonelogin-card-actions {
  box-sizing: border-box;
  display: table;
  font-size: 14px;
  padding: 8px 24px 24px;
  text-align: left;
  width: 100%
}

.phonelogin-form-links {
  display: table-cell;
  vertical-align: middle;
  width: 100%
}

.phonelogin-form-actions {
  display: table-cell;
  text-align: right;
  white-space: nowrap;
  width: 100%
}

.phonelogin-subtitle,.phonelogin-title {
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
  text-align: left
}

.phonelogin-id-page-password-recovery-email-sent p.phonelogin-text,.phonelogin-subtitle {
  margin: 16px 0
}

.phonelogin-title {
  padding-bottom: 16px;
  font-weight: 700;
  line-height: 1.2;
}

.phonelogin-text {
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-size: 16px;
  line-height: 24px;
  text-align: left
}

.phonelogin-error,.phonelogin-tos {
  line-height: 16px;
  text-align: left;
  direction: ltr
}

.phonelogin-text-emphasis {
  font-weight: 700
}

.phonelogin-error {
  color: #dd2c00;
  font-size: 12px;
  margin: 0
}

.phonelogin-text-input-error {
  margin: -16px 0 16px
}

.phonelogin-list-item {
  direction: ltr;
  margin: 0;
  padding: 0;
  text-align: left
}

.phonelogin-hidden {
  display: none
}

.phonelogin-relative-wrapper {
  position: relative
}

.phonelogin-input-hidden {
  display: none;
}

.phonelogin-label {
  color: rgba(0,0,0,.54);
  direction: ltr;
  font-size: 16px;
  text-align: left
}

.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,.mdl-textfield--floating-label.is-focused .mdl-textfield__label {
  color: #757575
}

.phonelogin-input,.phonelogin-input-invalid {
  @include border-radius(0);
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-size: 16px;
  width: 100%
}

input.phonelogin-input,input.phonelogin-input-invalid {
  direction: ltr;
  text-align: left
}

.phonelogin-input-invalid {
  border-color: #dd2c00
}

.phonelogin-textfield {
  width: 100%
}

.phonelogin-textfield.mdl-textfield .phonelogin-input {
  border-color: rgba(0,0,0,.12);
  padding: 0 5px;
}

.phonelogin-textfield.mdl-textfield .phonelogin-label::after {
  background-color: #3f51b5
}

.phonelogin-textfield-invalid.mdl-textfield .phonelogin-input {
  border-color: #dd2c00
}

.phonelogin-textfield-invalid.mdl-textfield .phonelogin-label::after {
  background-color: #dd2c00
}

.phonelogin-button {
  display: inline-block;
  height: 36px;
  margin-left: 8px;
  min-width: 88px
}

.phonelogin-link {
  color: #4285f4;
  font-variant: normal;
  font-weight: 400;
  text-decoration: none
}

.phonelogin-link:hover {
  text-decoration: underline
}

.phonelogin-indent {
  margin-left: 1em
}

.phonelogin-tos {
  color: #757575;
  font-size: 12px;
  margin-bottom: 24px;
  margin-top: 0
}

.phonelogin-provider-sign-in-footer>.phonelogin-tos {
  text-align: center
}

.phonelogin-tos-list {
  list-style: none;
  text-align: right
}

.phonelogin-inline-list-item {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px
}

.phonelogin-page-provider-sign-in {
  background: inherit
}

.phonelogin-idp-list {
  list-style: none;
  margin: 1em 0;
  padding: 0
}

.phonelogin-idp-button {
  direction: ltr;
  font-weight: 500;
  height: auto;
  line-height: normal;
  max-width: 220px;
  min-height: 40px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
}

.phonelogin-idp-list>.phonelogin-list-item {
  margin-bottom: 15px;
  text-align: center
}

.phonelogin-idp-icon-wrapper {
  display: table-cell;
  vertical-align: middle
}

.phonelogin-idp-favicon,.phonelogin-idp-icon {
  border: none;
  display: inline-block;
  vertical-align: middle
}

.phonelogin-idp-icon {
  height: 18px;
  width: 18px
}

.phonelogin-idp-favicon {
  height: 14px;
  margin-right: 5px;
  width: 14px
}

.phonelogin-idp-text {
  color: #fff;
  display: table-cell;
  font-size: 14px;
  padding-left: 16px;
  text-transform: none;
  vertical-align: middle
}

.phonelogin-idp-text.phonelogin-idp-text-long {
  display: table-cell
}

.phonelogin-idp-text.phonelogin-idp-text-short {
  display: none
}

@media (max-width: 268px) {
  .phonelogin-idp-text.phonelogin-idp-text-long {
      display:none
  }

  .phonelogin-idp-text.phonelogin-idp-text-short {
      display: table-cell
  }
}

.phonelogin-dialog-icon-wrapper,.phonelogin-progress-dialog-message {
  display: table-cell;
  vertical-align: middle
}

@media (max-width: 320px) {
  .phonelogin-recaptcha-container>div>div {
      transform:scale(.9);
      -webkit-transform: scale(.9);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0
  }
}

.phonelogin-idp-password,.phonelogin-idp-password:hover,.mdl-button.phonelogin-idp-password:active,.mdl-button.phonelogin-idp-password:focus {
  background-color: #db4437
}

.phonelogin-idp-phone,.phonelogin-idp-phone:hover,.mdl-button.phonelogin-idp-phone:active,.mdl-button.phonelogin-idp-phone:focus {
  background-color: #02bd7e
}

.phonelogin-idp-google,.phonelogin-idp-google:hover,.mdl-button.phonelogin-idp-google:active,.mdl-button.phonelogin-idp-google:focus {
  background-color: #fff
}

.phonelogin-idp-google>.phonelogin-idp-text {
  color: #757575
}

.phonelogin-idp-github,.phonelogin-idp-github:hover,.mdl-button.phonelogin-idp-github:active,.mdl-button.phonelogin-idp-github:focus {
  background-color: #333
}

.phonelogin-idp-facebook,.phonelogin-idp-facebook:hover,.mdl-button.phonelogin-idp-facebook:active,.mdl-button.phonelogin-idp-facebook:focus {
  background-color: #3b5998
}

.phonelogin-idp-twitter,.phonelogin-idp-twitter:hover,.mdl-button.phonelogin-idp-twitter:active,.mdl-button.phonelogin-idp-twitter:focus {
  background-color: #55acee
}

.phonelogin-idp-anonymous,.phonelogin-idp-anonymous:hover,.mdl-button.phonelogin-idp-anonymous:active,.mdl-button.phonelogin-idp-anonymous:focus {
  background-color: #f4b400
}

.phonelogin-info-bar {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  box-shadow: 0 2px 4px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.2);
  -moz-box-shadow: 0 2px 4px rgba(0,0,0,.2);
  left: 10%;
  padding: 8px 16px;
  position: absolute;
  right: 10%;
  text-align: center;
  top: 0
}

.phonelogin-info-bar-message {
  font-size: 12px;
  margin: 0
}

.phonelogin-dialog {
  box-sizing: border-box;
  color: rgba(0,0,0,.87);
  font: 16px Roboto,arial,sans-serif;
  height: auto;
  padding: 24px;
  text-align: left
}

.phonelogin-dialog-icon {
  float: left;
  height: 40px;
  margin-right: 24px;
  width: 40px
}

.phonelogin-progress-dialog-message {
  font-size: 16px;
  font-weight: 400;
  min-height: 40px
}

.phonelogin-progress-dialog-loading-icon {
  height: 28px;
  margin: 6px 30px 6px 6px;
  width: 28px
}

.phonelogin-icon-done {
  background-image: url(https://www.gstatic.com/images/icons/material/system/2x/done_googgreen_36dp.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 36px 36px
}

.phonelogin-phone-number {
  display: flex
}

.phonelogin-country-selector {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/arrow_drop_down_grey600_18dp.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 18px auto;
  @include border-radius(0);
  border-bottom: 1px solid rgba(0,0,0,.12);
  color: rgba(0,0,0,.87);
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 400;
  max-height: 50px;
  height: initial;
  line-height: normal;
  margin: 20px 10px 20px 0;
  padding: 0 5px;
  width: 70px;
  position: relative;
  text-align: left;
  button {
    text-align: left;
    padding: 0;
    height: 50px;
    width: 100%;
    cursor: pointer;
  }
}

.phonelogin-country-selector-flag {
  display: inline-block;
  margin-right: 1ex
}

.phonelogin-list-box-icon-wrapper,.phonelogin-list-box-label-wrapper {
  display: table-cell;
  vertical-align: top
}

.phonelogin-flag {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/flags_sprite_2x.png);
  background-size: 100% auto;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .54));
  height: 14px;
  width: 24px
}

.phonelogin-countryname {
  padding: 2px 0 0;
  font-size: 11px;
  color: #AAAAAA;
}

.phonelogin-list-box-dialog {
  max-height: 90%;
  overflow: auto;
  padding: 8px 0 0
}

.phonelogin-list-box-actions {
  padding-bottom: 8px
}

.phonelogin-list-box-icon-wrapper {
  padding-right: 24px
}

.phonelogin-list-box-dialog-button {
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-size: 16px;
  font-weight: 400;
  height: initial;
  line-height: normal;
  min-height: 48px;
  padding: 14px 24px;
  text-align: left;
  text-transform: none;
  width: 100%
}

.phonelogin-country-dropdown {
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  display: block;
  z-index: 9;
  left: 0;
  background: #ffffff;
  width: 250px;
  cursor: pointer;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, .25));
  li {
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      background-color: rgba(158, 158, 158, 0.2);
    }
  }
}

.phonelogin-phone-number-error {
  margin-left: 114px
}

.mdl-progress.phonelogin-busy-indicator {
  height: 2px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 100%
}

.mdl-spinner.phonelogin-busy-indicator {
  height: 56px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 30%;
  width: 56px
}

.phonelogin-callback-indicator-container .phonelogin-busy-indicator {
  top: 0
}

.phonelogin-callback-indicator-container {
  height: 120px
}

.phonelogin-new-password-component {
  display: inline-block;
  position: relative;
  width: 100%
}

.phonelogin-input-floating-button {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: 24px;
  position: absolute;
  right: 0;
  top: 20px;
  width: 24px
}

.phonelogin-input-toggle-on {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_black_24dp.png)
}

.phonelogin-input-toggle-off {
  background-image: url(https://www.gstatic.com/images/icons/material/system/1x/visibility_off_black_24dp.png)
}

.phonelogin-input-toggle-focus {
  opacity: .87
}

.phonelogin-input-toggle-blur {
  opacity: .38
}

.phonelogin-recaptcha-wrapper {
  display: table;
  margin: 0 auto;
  padding-bottom: 8px
}

.phonelogin-recaptcha-container {
  display: table-cell
}

.phonelogin-recaptcha-error-wrapper {
  caption-side: bottom;
  display: table-caption
}

.phonelogin-change-phone-number-link {
  display: block
}

.phonelogin-resend-container {
  direction: ltr;
  margin: 0 0 30px;
  text-align: center
}

.phonelogin-id-resend-countdown {
  color: rgba(0,0,0,.38)
}

.phonelogin-id-page-phone-sign-in-start .phonelogin-form-actions div {
  float: left
}

@media (max-width: 480px) {
  .phonelogin-container {
      box-shadow:none;
      max-width: none;
      width: 100%
  }

  .phonelogin-card-header {
      padding: 16px 24px 0
  }

  .phonelogin-title {
      padding-bottom: 16px
  }

  .phonelogin-card-actions {
      padding-right: 24px
  }

  .phonelogin-busy-indicator {
      top: 0
  }
}

.mdl-textfield__label {
  font-weight: 400;
  margin-bottom: 0
}

.phonelogin-id-page-blank {
  background: inherit;
  height: 64px
}

.phonelogin-email-sent {
  background-image: url(https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/success_status.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 64px 64px;
  height: 64px;
  margin-top: 16px;
  text-align: center
}

.phonelogin-text-justify {
  text-align: justify
}

.phonelogin-flag-KY {
  background-position: 0 0
}

.phonelogin-flag-AC {
  background-position: 0 -14px
}

.phonelogin-flag-AE {
  background-position: 0 -28px
}

.phonelogin-flag-AF {
  background-position: 0 -42px
}

.phonelogin-flag-AG {
  background-position: 0 -56px
}

.phonelogin-flag-AI {
  background-position: 0 -70px
}

.phonelogin-flag-AL {
  background-position: 0 -84px
}

.phonelogin-flag-AM {
  background-position: 0 -98px
}

.phonelogin-flag-AO {
  background-position: 0 -112px
}

.phonelogin-flag-AQ {
  background-position: 0 -126px
}

.phonelogin-flag-AR {
  background-position: 0 -140px
}

.phonelogin-flag-AS {
  background-position: 0 -154px
}

.phonelogin-flag-AT {
  background-position: 0 -168px
}

.phonelogin-flag-AU {
  background-position: 0 -182px
}

.phonelogin-flag-AW {
  background-position: 0 -196px
}

.phonelogin-flag-AX {
  background-position: 0 -210px
}

.phonelogin-flag-AZ {
  background-position: 0 -224px
}

.phonelogin-flag-BA {
  background-position: 0 -238px
}

.phonelogin-flag-BB {
  background-position: 0 -252px
}

.phonelogin-flag-BD {
  background-position: 0 -266px
}

.phonelogin-flag-BE {
  background-position: 0 -280px
}

.phonelogin-flag-BF {
  background-position: 0 -294px
}

.phonelogin-flag-BG {
  background-position: 0 -308px
}

.phonelogin-flag-BH {
  background-position: 0 -322px
}

.phonelogin-flag-BI {
  background-position: 0 -336px
}

.phonelogin-flag-BJ {
  background-position: 0 -350px
}

.phonelogin-flag-BL {
  background-position: 0 -364px
}

.phonelogin-flag-BM {
  background-position: 0 -378px
}

.phonelogin-flag-BN {
  background-position: 0 -392px
}

.phonelogin-flag-BO {
  background-position: 0 -406px
}

.phonelogin-flag-BQ {
  background-position: 0 -420px
}

.phonelogin-flag-BR {
  background-position: 0 -434px
}

.phonelogin-flag-BS {
  background-position: 0 -448px
}

.phonelogin-flag-BT {
  background-position: 0 -462px
}

.phonelogin-flag-BV {
  background-position: 0 -476px
}

.phonelogin-flag-BW {
  background-position: 0 -490px
}

.phonelogin-flag-BY {
  background-position: 0 -504px
}

.phonelogin-flag-BZ {
  background-position: 0 -518px
}

.phonelogin-flag-CA {
  background-position: 0 -532px
}

.phonelogin-flag-CC {
  background-position: 0 -546px
}

.phonelogin-flag-CD {
  background-position: 0 -560px
}

.phonelogin-flag-CF {
  background-position: 0 -574px
}

.phonelogin-flag-CG {
  background-position: 0 -588px
}

.phonelogin-flag-CH {
  background-position: 0 -602px
}

.phonelogin-flag-CI {
  background-position: 0 -616px
}

.phonelogin-flag-CK {
  background-position: 0 -630px
}

.phonelogin-flag-CL {
  background-position: 0 -644px
}

.phonelogin-flag-CM {
  background-position: 0 -658px
}

.phonelogin-flag-CN {
  background-position: 0 -672px
}

.phonelogin-flag-CO {
  background-position: 0 -686px
}

.phonelogin-flag-CP {
  background-position: 0 -700px
}

.phonelogin-flag-CR {
  background-position: 0 -714px
}

.phonelogin-flag-CU {
  background-position: 0 -728px
}

.phonelogin-flag-CV {
  background-position: 0 -742px
}

.phonelogin-flag-CW {
  background-position: 0 -756px
}

.phonelogin-flag-CX {
  background-position: 0 -770px
}

.phonelogin-flag-CY {
  background-position: 0 -784px
}

.phonelogin-flag-CZ {
  background-position: 0 -798px
}

.phonelogin-flag-DE {
  background-position: 0 -812px
}

.phonelogin-flag-DG {
  background-position: 0 -826px
}

.phonelogin-flag-DJ {
  background-position: 0 -840px
}

.phonelogin-flag-DK {
  background-position: 0 -854px
}

.phonelogin-flag-DM {
  background-position: 0 -868px
}

.phonelogin-flag-DO {
  background-position: 0 -882px
}

.phonelogin-flag-DZ {
  background-position: 0 -896px
}

.phonelogin-flag-EA {
  background-position: 0 -910px
}

.phonelogin-flag-EC {
  background-position: 0 -924px
}

.phonelogin-flag-EE {
  background-position: 0 -938px
}

.phonelogin-flag-EG {
  background-position: 0 -952px
}

.phonelogin-flag-EH {
  background-position: 0 -966px
}

.phonelogin-flag-ER {
  background-position: 0 -980px
}

.phonelogin-flag-ES {
  background-position: 0 -994px
}

.phonelogin-flag-ET {
  background-position: 0 -1008px
}

.phonelogin-flag-EU {
  background-position: 0 -1022px
}

.phonelogin-flag-FI {
  background-position: 0 -1036px
}

.phonelogin-flag-FJ {
  background-position: 0 -1050px
}

.phonelogin-flag-FK {
  background-position: 0 -1064px
}

.phonelogin-flag-FM {
  background-position: 0 -1078px
}

.phonelogin-flag-FO {
  background-position: 0 -1092px
}

.phonelogin-flag-FR {
  background-position: 0 -1106px
}

.phonelogin-flag-GA {
  background-position: 0 -1120px
}

.phonelogin-flag-GB {
  background-position: 0 -1134px
}

.phonelogin-flag-GD {
  background-position: 0 -1148px
}

.phonelogin-flag-GE {
  background-position: 0 -1162px
}

.phonelogin-flag-GF {
  background-position: 0 -1176px
}

.phonelogin-flag-GG {
  background-position: 0 -1190px
}

.phonelogin-flag-GH {
  background-position: 0 -1204px
}

.phonelogin-flag-GI {
  background-position: 0 -1218px
}

.phonelogin-flag-GL {
  background-position: 0 -1232px
}

.phonelogin-flag-GM {
  background-position: 0 -1246px
}

.phonelogin-flag-GN {
  background-position: 0 -1260px
}

.phonelogin-flag-GP {
  background-position: 0 -1274px
}

.phonelogin-flag-GQ {
  background-position: 0 -1288px
}

.phonelogin-flag-GR {
  background-position: 0 -1302px
}

.phonelogin-flag-GS {
  background-position: 0 -1316px
}

.phonelogin-flag-GT {
  background-position: 0 -1330px
}

.phonelogin-flag-GU {
  background-position: 0 -1344px
}

.phonelogin-flag-GW {
  background-position: 0 -1358px
}

.phonelogin-flag-GY {
  background-position: 0 -1372px
}

.phonelogin-flag-HK {
  background-position: 0 -1386px
}

.phonelogin-flag-HM {
  background-position: 0 -1400px
}

.phonelogin-flag-HN {
  background-position: 0 -1414px
}

.phonelogin-flag-HR {
  background-position: 0 -1428px
}

.phonelogin-flag-HT {
  background-position: 0 -1442px
}

.phonelogin-flag-HU {
  background-position: 0 -1456px
}

.phonelogin-flag-IC {
  background-position: 0 -1470px
}

.phonelogin-flag-ID {
  background-position: 0 -1484px
}

.phonelogin-flag-IE {
  background-position: 0 -1498px
}

.phonelogin-flag-IL {
  background-position: 0 -1512px
}

.phonelogin-flag-IM {
  background-position: 0 -1526px
}

.phonelogin-flag-IN {
  background-position: 0 -1540px
}

.phonelogin-flag-IO {
  background-position: 0 -1554px
}

.phonelogin-flag-IQ {
  background-position: 0 -1568px
}

.phonelogin-flag-IR {
  background-position: 0 -1582px
}

.phonelogin-flag-IS {
  background-position: 0 -1596px
}

.phonelogin-flag-IT {
  background-position: 0 -1610px
}

.phonelogin-flag-JE {
  background-position: 0 -1624px
}

.phonelogin-flag-JM {
  background-position: 0 -1638px
}

.phonelogin-flag-JO {
  background-position: 0 -1652px
}

.phonelogin-flag-JP {
  background-position: 0 -1666px
}

.phonelogin-flag-KE {
  background-position: 0 -1680px
}

.phonelogin-flag-KG {
  background-position: 0 -1694px
}

.phonelogin-flag-KH {
  background-position: 0 -1708px
}

.phonelogin-flag-KI {
  background-position: 0 -1722px
}

.phonelogin-flag-KM {
  background-position: 0 -1736px
}

.phonelogin-flag-KN {
  background-position: 0 -1750px
}

.phonelogin-flag-KP {
  background-position: 0 -1764px
}

.phonelogin-flag-KR {
  background-position: 0 -1778px
}

.phonelogin-flag-KW {
  background-position: 0 -1792px
}

.phonelogin-flag-AD {
  background-position: 0 -1806px
}

.phonelogin-flag-KZ {
  background-position: 0 -1820px
}

.phonelogin-flag-LA {
  background-position: 0 -1834px
}

.phonelogin-flag-LB {
  background-position: 0 -1848px
}

.phonelogin-flag-LC {
  background-position: 0 -1862px
}

.phonelogin-flag-LI {
  background-position: 0 -1876px
}

.phonelogin-flag-LK {
  background-position: 0 -1890px
}

.phonelogin-flag-LR {
  background-position: 0 -1904px
}

.phonelogin-flag-LS {
  background-position: 0 -1918px
}

.phonelogin-flag-LT {
  background-position: 0 -1932px
}

.phonelogin-flag-LU {
  background-position: 0 -1946px
}

.phonelogin-flag-LV {
  background-position: 0 -1960px
}

.phonelogin-flag-LY {
  background-position: 0 -1974px
}

.phonelogin-flag-MA {
  background-position: 0 -1988px
}

.phonelogin-flag-MC {
  background-position: 0 -2002px
}

.phonelogin-flag-MD {
  background-position: 0 -2016px
}

.phonelogin-flag-ME {
  background-position: 0 -2030px
}

.phonelogin-flag-MF {
  background-position: 0 -2044px
}

.phonelogin-flag-MG {
  background-position: 0 -2058px
}

.phonelogin-flag-MH {
  background-position: 0 -2072px
}

.phonelogin-flag-MK {
  background-position: 0 -2086px
}

.phonelogin-flag-ML {
  background-position: 0 -2100px
}

.phonelogin-flag-MM {
  background-position: 0 -2114px
}

.phonelogin-flag-MN {
  background-position: 0 -2128px
}

.phonelogin-flag-MO {
  background-position: 0 -2142px
}

.phonelogin-flag-MP {
  background-position: 0 -2156px
}

.phonelogin-flag-MQ {
  background-position: 0 -2170px
}

.phonelogin-flag-MR {
  background-position: 0 -2184px
}

.phonelogin-flag-MS {
  background-position: 0 -2198px
}

.phonelogin-flag-MT {
  background-position: 0 -2212px
}

.phonelogin-flag-MU {
  background-position: 0 -2226px
}

.phonelogin-flag-MV {
  background-position: 0 -2240px
}

.phonelogin-flag-MW {
  background-position: 0 -2254px
}

.phonelogin-flag-MX {
  background-position: 0 -2268px
}

.phonelogin-flag-MY {
  background-position: 0 -2282px
}

.phonelogin-flag-MZ {
  background-position: 0 -2296px
}

.phonelogin-flag-NA {
  background-position: 0 -2310px
}

.phonelogin-flag-NC {
  background-position: 0 -2324px
}

.phonelogin-flag-NE {
  background-position: 0 -2338px
}

.phonelogin-flag-NF {
  background-position: 0 -2352px
}

.phonelogin-flag-NG {
  background-position: 0 -2366px
}

.phonelogin-flag-NI {
  background-position: 0 -2380px
}

.phonelogin-flag-NL {
  background-position: 0 -2394px
}

.phonelogin-flag-NO {
  background-position: 0 -2408px
}

.phonelogin-flag-NP {
  background-position: 0 -2422px
}

.phonelogin-flag-NR {
  background-position: 0 -2436px
}

.phonelogin-flag-NU {
  background-position: 0 -2450px
}

.phonelogin-flag-NZ {
  background-position: 0 -2464px
}

.phonelogin-flag-OM {
  background-position: 0 -2478px
}

.phonelogin-flag-PA {
  background-position: 0 -2492px
}

.phonelogin-flag-PE {
  background-position: 0 -2506px
}

.phonelogin-flag-PF {
  background-position: 0 -2520px
}

.phonelogin-flag-PG {
  background-position: 0 -2534px
}

.phonelogin-flag-PH {
  background-position: 0 -2548px
}

.phonelogin-flag-PK {
  background-position: 0 -2562px
}

.phonelogin-flag-PL {
  background-position: 0 -2576px
}

.phonelogin-flag-PM {
  background-position: 0 -2590px
}

.phonelogin-flag-PN {
  background-position: 0 -2604px
}

.phonelogin-flag-PR {
  background-position: 0 -2618px
}

.phonelogin-flag-PS {
  background-position: 0 -2632px
}

.phonelogin-flag-PT {
  background-position: 0 -2646px
}

.phonelogin-flag-PW {
  background-position: 0 -2660px
}

.phonelogin-flag-PY {
  background-position: 0 -2674px
}

.phonelogin-flag-QA {
  background-position: 0 -2688px
}

.phonelogin-flag-RE {
  background-position: 0 -2702px
}

.phonelogin-flag-RO {
  background-position: 0 -2716px
}

.phonelogin-flag-RS {
  background-position: 0 -2730px
}

.phonelogin-flag-RU {
  background-position: 0 -2744px
}

.phonelogin-flag-RW {
  background-position: 0 -2758px
}

.phonelogin-flag-SA {
  background-position: 0 -2772px
}

.phonelogin-flag-SB {
  background-position: 0 -2786px
}

.phonelogin-flag-SC {
  background-position: 0 -2800px
}

.phonelogin-flag-SD {
  background-position: 0 -2814px
}

.phonelogin-flag-SE {
  background-position: 0 -2828px
}

.phonelogin-flag-SG {
  background-position: 0 -2842px
}

.phonelogin-flag-SH {
  background-position: 0 -2856px
}

.phonelogin-flag-SI {
  background-position: 0 -2870px
}

.phonelogin-flag-SJ {
  background-position: 0 -2884px
}

.phonelogin-flag-SK {
  background-position: 0 -2898px
}

.phonelogin-flag-SL {
  background-position: 0 -2912px
}

.phonelogin-flag-SM {
  background-position: 0 -2926px
}

.phonelogin-flag-SN {
  background-position: 0 -2940px
}

.phonelogin-flag-SO {
  background-position: 0 -2954px
}

.phonelogin-flag-SR {
  background-position: 0 -2968px
}

.phonelogin-flag-SS {
  background-position: 0 -2982px
}

.phonelogin-flag-ST {
  background-position: 0 -2996px
}

.phonelogin-flag-SV {
  background-position: 0 -3010px
}

.phonelogin-flag-SX {
  background-position: 0 -3024px
}

.phonelogin-flag-SY {
  background-position: 0 -3038px
}

.phonelogin-flag-SZ {
  background-position: 0 -3052px
}

.phonelogin-flag-TA {
  background-position: 0 -3066px
}

.phonelogin-flag-TC {
  background-position: 0 -3080px
}

.phonelogin-flag-TD {
  background-position: 0 -3094px
}

.phonelogin-flag-TF {
  background-position: 0 -3108px
}

.phonelogin-flag-TG {
  background-position: 0 -3122px
}

.phonelogin-flag-TH {
  background-position: 0 -3136px
}

.phonelogin-flag-TJ {
  background-position: 0 -3150px
}

.phonelogin-flag-TK {
  background-position: 0 -3164px
}

.phonelogin-flag-TL {
  background-position: 0 -3178px
}

.phonelogin-flag-TM {
  background-position: 0 -3192px
}

.phonelogin-flag-TN {
  background-position: 0 -3206px
}

.phonelogin-flag-TO {
  background-position: 0 -3220px
}

.phonelogin-flag-TR {
  background-position: 0 -3234px
}

.phonelogin-flag-TT {
  background-position: 0 -3248px
}

.phonelogin-flag-TV {
  background-position: 0 -3262px
}

.phonelogin-flag-TW {
  background-position: 0 -3276px
}

.phonelogin-flag-TZ {
  background-position: 0 -3290px
}

.phonelogin-flag-UA {
  background-position: 0 -3304px
}

.phonelogin-flag-UG {
  background-position: 0 -3318px
}

.phonelogin-flag-UM {
  background-position: 0 -3332px
}

.phonelogin-flag-UN {
  background-position: 0 -3346px
}

.phonelogin-flag-US {
  background-position: 0 -3360px
}

.phonelogin-flag-UY {
  background-position: 0 -3374px
}

.phonelogin-flag-UZ {
  background-position: 0 -3388px
}

.phonelogin-flag-VA {
  background-position: 0 -3402px
}

.phonelogin-flag-VC {
  background-position: 0 -3416px
}

.phonelogin-flag-VE {
  background-position: 0 -3430px
}

.phonelogin-flag-VG {
  background-position: 0 -3444px
}

.phonelogin-flag-VI {
  background-position: 0 -3458px
}

.phonelogin-flag-VN {
  background-position: 0 -3472px
}

.phonelogin-flag-VU {
  background-position: 0 -3486px
}

.phonelogin-flag-WF {
  background-position: 0 -3500px
}

.phonelogin-flag-WS {
  background-position: 0 -3514px
}

.phonelogin-flag-XK {
  background-position: 0 -3528px
}

.phonelogin-flag-YE {
  background-position: 0 -3542px
}

.phonelogin-flag-YT {
  background-position: 0 -3556px
}

.phonelogin-flag-ZA {
  background-position: 0 -3570px
}

.phonelogin-flag-ZM {
  background-position: 0 -3584px
}

.phonelogin-flag-ZW {
  background-position: 0 -3598px
}

#recaptcha-container {
  display: none;
}